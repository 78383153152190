<template>
  <v-app style="background-color: #191919; overflow-x: hidden;">
    <v-app-bar app extended absolute color="#191919" class="extended-app-bar">
      <v-container class="pa-0">
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="auto">
            <v-img src="@/assets/baphomet.jpg" alt="Logo" class="logo rounded-circle"></v-img>
          </v-col>
          <v-col cols="auto" class="spacer"></v-col>
          <v-col cols="auto">
            <v-toolbar-title class="custom-title">
              Headbang Zamanı
            </v-toolbar-title>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main class="main-content">
      <v-container v-if="loading" class="d-flex justify-center align-center fill-height pa-0">
        <v-progress-circular indeterminate color="white" size="100" width="8">
          <template v-slot:default>
            <span class="white--text">Yükleniyor</span>
          </template>
        </v-progress-circular>
      </v-container>

      <v-container v-else-if="error" class="text-center error-container pa-4">
        <v-alert type="error" elevation="2" prominent>
          {{ error }}
        </v-alert>
      </v-container>

      <v-container v-else class="pa-4">
        <div class="filters-section">
          <div class="filters-row">
            <v-chip-group class="filter-group">
              <v-chip
                color="rgba(255, 255, 255, 0.15)"
                @click="shouldShowPastEvents = !shouldShowPastEvents"
                class="filter-chip"
                variant="elevated"
              >
                <v-icon start>{{ shouldShowPastEvents ? 'mdi-check' : 'mdi-history' }}</v-icon>
                Geçmiş Konserleri Göster
              </v-chip>
            </v-chip-group>

            <div class="filter-buttons">
              <v-btn-toggle
                v-model="sortBy"
                mandatory
                class="sort-toggle"
                density="comfortable"
                color="white"
              >
                <v-btn value="daysLeft" class="sort-btn">
                  <v-icon start>mdi-calendar</v-icon>
                  Tarihe Göre
                </v-btn>
                <v-btn value="name" class="sort-btn">
                  <v-icon start>mdi-sort-alphabetical-variant</v-icon>
                  İsme Göre
                </v-btn>
              </v-btn-toggle>

              <div class="city-toggle-wrapper">
                <v-btn-toggle
                  v-model="selectedCity"
                  mandatory
                  class="city-toggle"
                  density="comfortable"
                  color="white"
                >
                  <v-btn value="All" class="city-btn">Tümü</v-btn>
                  <v-btn v-for="city in cities" :key="city" :value="city" class="city-btn">
                    {{ city }}
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>
          </div>
        </div>

        <v-slide-y-transition group>
          <v-container v-if="sortedArray.length === 0" key="no-results" class="text-center empty-state">
            <v-icon size="64" color="grey">mdi-music-off</v-icon>
            <h2 class="empty-state-text">Seçtiğiniz kriterlere uygun konser bulunamadı.</h2>
          </v-container>

          <div v-else class="concert-cards-container">
            <ConcertCard
              v-for="event in sortedArray"
              :key="event.id || event.name"
              v-bind="event"
              :isSmallScreen="isSmallScreen"
              :daysLeft="daysLeft(event.date)"
            />
          </div>
        </v-slide-y-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { db, analytics } from "@/firebase"
import { getDocs, collection } from "firebase/firestore"
import { logEvent } from "firebase/analytics";
import ConcertCard from "@/components/ConcertCard.vue"

export default {
  name: "HomeView",
  components: {
    ConcertCard,
  },
  data: () => ({
    events: [],
    loading: true,
    error: null,
    sortBy: "daysLeft",
    selectedCity: "All",
    cities: ["İstanbul", "Ankara", "İzmir", "Bursa", "Eskişehir"],
    isSmallScreen: false,
    shouldShowPastEvents: false,
    resizeObserver: null
  }),
  methods: {
    async fetchEvents() {
      try {
        const eventsCollection = await getDocs(collection(db, "events"))
        this.events = eventsCollection.docs.map(doc => ({
          ...doc.data(),
          id: doc.id
        }))
        logEvent(analytics, 'events_fetch_succeed');
      } catch (error) {
        this.error = "Bir hata oluştu. Sayfayı yenileyerek tekrar deneyiniz."
        console.error('Error:', error);
        logEvent(analytics, 'events_fetch_failed');
      } finally {
        this.loading = false
      }
    },
    isConcertPassed(date) {
      return this.daysLeft(date) < 0;
    },
    daysLeft(date) {
      const currentDate = new Date()
      const concertDate = new Date(date)
      concertDate.setHours(concertDate.getHours() - 3);
      return Math.floor((concertDate - currentDate) / (1000 * 60 * 60 * 24));
    },
    handleResize() {
      this.isSmallScreen = window.innerWidth < 600;
    }
  },
  computed: {
    sortedArray() {
      let filteredEvents = [...this.events];

      // Şehir filtresi
      if (this.selectedCity !== "All") {
        filteredEvents = filteredEvents.filter(event => event.city === this.selectedCity);
      }

      // Geçmiş konserler filtresi
      if (!this.shouldShowPastEvents) {
        filteredEvents = filteredEvents.filter(event => !this.isConcertPassed(event.date));
      }

      // Sıralama
      return filteredEvents.sort((a, b) => {
        if (this.sortBy === "name") {
          return (a.name || "").localeCompare(b.name || "");
        }
        // Tarihe göre sıralama - en yakın tarih üstte
        return this.daysLeft(a.date) - this.daysLeft(b.date);
      });
    }
  },
  created() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  mounted() {
    this.fetchEvents();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style>
/* Temel stiller */
.v-application {
  overflow-x: hidden;
  width: 100%;
}

.v-main {
  padding: 0 !important;
}

.v-container {
  max-width: 800px !important;
  width: 100%;
  margin: 0 auto;
}

/* Header stilleri */
.extended-app-bar {
  position: relative !important;
  text-align: center;
  margin-bottom: 8px;
}

.custom-title {
  margin-top: 32px;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.2;
  color: whitesmoke;
}

.logo {
  width: 60px;
  height: 60px;
  margin-top: 32px;
  margin-right: 16px;
}

/* Filtre stilleri */
.filters-section {
  margin: 0;
  width: 100%;
}

.filters-row {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.filter-group {
  margin: 0 !important;
  padding: 0 !important;
}

.filter-buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}

.filter-chip {
  min-height: 36px !important;
  height: 36px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: 0 !important;
  background: rgba(255, 255, 255, 0.05) !important;
}

.filter-chip :deep(.v-chip__content) {
  height: 36px !important;
  padding: 0 16px !important;
  gap: 8px !important;
}

.filter-chip :deep(.v-icon) {
  margin: 0 !important;
  height: 20px !important;
  width: 20px !important;
}

.filter-chip.v-chip--selected {
  background: rgba(255, 255, 255, 0.1) !important;
}

.concert-cards-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
}

@media (min-width: 600px) {
  .filters-row {
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .filter-buttons {
    flex-direction: row;
    flex: 1;
    gap: 8px;
  }
}

@media (max-width: 600px) {
  .extended-app-bar {
    margin-bottom: 4px;
  }

  .filters-row {
    gap: 4px;
  }

  .filter-buttons {
    gap: 4px;
  }

  .concert-cards-container {
    gap: 12px;
    margin-top: 8px;
  }
}

@media (max-width: 480px) {
  .extended-app-bar {
    margin-bottom: 2px;
  }

  .concert-cards-container {
    gap: 8px;
    margin-top: 6px;
  }
}

.logo {
  width: 60px;
  height: 60px;
  margin-top: 40px;
}

.content-container {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 24px;
}

.filters-row {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.filter-chip {
  font-size: 0.9rem;
  transition: all 0.3s ease;
  color: whitesmoke !important;
}

.filter-buttons {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.sort-toggle, .city-toggle {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  overflow: hidden;
  height: 36px;
}

.sort-btn, .city-btn {
  height: 36px !important;
  color: rgba(255, 255, 255, 0.9) !important;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  text-transform: none;
  transition: all 0.3s ease;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  background-color: transparent;
  padding: 0 12px !important;
  min-width: auto !important;
  width: auto !important;
}

.sort-btn:last-child, .city-btn:last-child {
  border-right: none;
}

.sort-btn.v-btn--active, .city-btn.v-btn--active {
  background-color: whitesmoke !important;
  color: #191919 !important;
  font-weight: 500;
}

.sort-btn:hover, .city-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.concert-cards-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.empty-state {
  padding: 48px 0;
}

.empty-state-text {
  color: whitesmoke;
  margin-top: 16px;
  font-size: 1.2rem;
  opacity: 0.8;
}

.error-container {
  margin-top: 48px;
}

/* Responsive Styles */
@media only screen and (max-width: 600px) {
  .custom-title {
    font-size: 24px;
    margin-left: -20px;
  }

  .logo {
    width: 48px;
    height: 48px;
  }

  .filters-row {
    flex-direction: column;
    align-items: stretch;
  }

  .filter-buttons {
    flex-direction: column;
    gap: 12px;
  }

  .sort-toggle, .city-toggle {
    width: 100%;
  }

  .content-container {
    padding: 16px;
  }

  .filters-section {
    margin-bottom: 16px;
  }
}

/* Animations */
.v-slide-y-transition-enter-active,
.v-slide-y-transition-leave-active {
  transition: all 0.3s ease;
}

.v-slide-y-transition-enter-from,
.v-slide-y-transition-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.v-slide-y-transition-move {
  transition: transform 0.3s ease;
}

.app-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 16px;
    min-height: 100vh;
    overflow-x: hidden;
}

.filter-group {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 24px;
    width: 100%;
}

.sort-group {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
    width: 100%;
}

.filter-chip {
    background: rgba(255, 255, 255, 0.05) !important;
}

.filter-chip.v-chip--selected {
    background: rgba(255, 255, 255, 0.1) !important;
}

.concert-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 48px 16px;
    text-align: center;
    color: #999;
}

.no-results-icon {
    font-size: 48px;
    opacity: 0.5;
}

@media (max-width: 600px) {
    .app-container {
        padding: 12px;
    }

    .filter-group, .sort-group {
        flex-direction: column;
        gap: 8px;
    }

    .v-btn-toggle {
        width: 100%;
    }

    .v-btn-toggle .v-btn {
        flex: 1;
    }

    .filter-chip {
        width: 100%;
        justify-content: center;
    }

    .no-results {
        padding: 32px 12px;
    }
}

@media (max-width: 480px) {
    .app-container {
        padding: 8px;
    }

    .filter-group, .sort-group {
        gap: 6px;
    }

    .no-results {
        padding: 24px 8px;
    }
}

@media (max-width: 360px) {
    .app-container {
        padding: 6px;
    }
}

@media (max-width: 600px) {
  .filter-chip :deep(.v-chip__content) {
    padding: 0 14px !important;
    gap: 6px !important;
  }
}

@media (max-width: 480px) {
  .filter-chip :deep(.v-chip__content) {
    padding: 0 12px !important;
    gap: 4px !important;
  }
}

@media (max-width: 600px) {
  .filter-chip {
    min-height: 32px !important;
    height: 32px !important;
  }

  .filter-chip :deep(.v-chip__content) {
    height: 32px !important;
    padding: 0 14px !important;
    gap: 6px !important;
  }

  .filter-chip :deep(.v-icon) {
    height: 18px !important;
    width: 18px !important;
  }
}

@media (max-width: 480px) {
  .filter-chip {
    min-height: 30px !important;
    height: 30px !important;
  }

  .filter-chip :deep(.v-chip__content) {
    height: 30px !important;
    padding: 0 12px !important;
    gap: 4px !important;
  }

  .filter-chip :deep(.v-icon) {
    height: 16px !important;
    width: 16px !important;
  }
}

/* Yeni eklenen stiller */
.city-toggle-wrapper {
  flex: 1;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.city-toggle-wrapper::-webkit-scrollbar {
  display: none;
}

.city-toggle {
  display: inline-flex;
  min-width: min-content;
  white-space: nowrap;
}

.sort-toggle {
  width: auto;
}

/* Responsive Styles */
@media only screen and (max-width: 600px) {
  .filter-buttons {
    flex-direction: column;
    gap: 12px;
  }

  .sort-toggle {
    width: 100%;
  }

  .city-toggle-wrapper {
    width: 100%;
  }

  .filters-row {
    gap: 12px;
  }
}

@media only screen and (max-width: 480px) {
  .filter-buttons {
    gap: 8px;
  }

  .filters-row {
    gap: 8px;
  }
}

@media only screen and (max-width: 360px) {
  .filter-buttons {
    gap: 6px;
  }

  .filters-row {
    gap: 6px;
  }
}
</style> 