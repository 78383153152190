<template>
    <div class="concert-item" :class="{ 'concert-passed': daysLeft < 0 }">
        <!-- Tarih Bölümü -->
        <div class="date-section">
            <div class="date-box">
                <span class="date-day">{{ formatDate('day') }}</span>
                <span class="date-month">{{ formatDate('month') }}</span>
            </div>
            <div class="days-left" :class="{
                'days-today': daysLeft === 0,
                'days-passed': daysLeft < 0
            }">
                <span v-if="daysLeft >= 0">{{ daysLeftInfoText }}</span>
            </div>
        </div>

        <!-- Konser Detayları -->
        <div class="concert-details">
            <h3 class="concert-name">{{ name }}</h3>
            <div class="venue-info">
                <v-icon size="small" color="grey">mdi-map-marker</v-icon>
                <span>{{ venue }}, {{ city }}</span>
            </div>
        </div>

        <!-- Bilet Butonu -->
        <div class="ticket-section">
            <v-btn
                v-if="showBuyButton"
                class="buy-ticket-btn"
                variant="outlined"
                :ripple="false"
                @click="dialog = true"
            >
                <v-icon start>mdi-ticket</v-icon>
                Bilet Al
            </v-btn>
            <span v-else-if="daysLeft < 0" class="no-ticket">Konser tarihi geçti</span>
        </div>

        <!-- Bilet Dialog -->
        <v-dialog v-model="dialog" max-width="400">
            <v-card class="ticket-dialog">
                <v-card-title class="dialog-title">
                    <span>Bilet Satış Platformları</span>
                    <v-btn icon variant="text" @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                
                <v-card-text class="dialog-content">
                    <div class="ticket-options">
                        <v-btn
                            v-for="(link, provider) in validTicketLinks"
                            :key="provider"
                            class="ticket-option-btn"
                            variant="outlined"
                            block
                            @click="openLink(link)"
                            :disabled="provider === 'hammer'"
                        >
                            <span class="text-uppercase">{{ formatProviderName(provider) }}</span>
                            <span v-if="provider === 'hammer'" class="coming-soon">(KAĞIT BİLET)</span>
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "ConcertCard",
    props: {
        name: String,
        venue: String,
        city: String,
        date: String,
        day: String,
        biletix: String,
        passo: String,
        hammer: String,
        isSmallScreen: Boolean,
        daysLeft: Number,
    },
    data: () => ({
        dialog: false
    }),
    computed: {
        showBuyButton() {
            return this.daysLeft >= 0 && this.hasValidTicketLink;
        },
        daysLeftInfoText() {
            if (this.daysLeft > 0) {
                return this.daysLeft + " gün kaldı"
            } else if (this.daysLeft === 0) {
                return "Konser bugün!"
            }
            return ""
        },
        hasValidTicketLink() {
            return Object.values(this.validTicketLinks).length > 0;
        },
        validTicketLinks() {
            const links = {
                biletix: this.biletix,
                passo: this.passo,
                hammer: this.hammer
            };
            
            return Object.fromEntries(
                Object.entries(links).filter(entry => this.isLinkValid(entry[1]))
            );
        }
    },
    methods: {
        formatProviderName(provider) {
            const names = {
                'biletix': 'Biletix',
                'passo': 'Passo',
                'hammer': 'Hammer Müzik'
            };
            return names[provider] || provider;
        },
        formatDate(type) {
            const dateObj = new Date(this.date);
            const months = ['OCA', 'ŞUB', 'MAR', 'NİS', 'MAY', 'HAZ', 'TEM', 'AĞU', 'EYL', 'EKİ', 'KAS', 'ARA'];
            
            if (type === 'day') {
                return dateObj.getDate();
            } else if (type === 'month') {
                return months[dateObj.getMonth()];
            }
        },
        isLinkValid(link) {
            return link !== null && link !== '' && link !== '-';
        },
        openLink(link) {
            if (this.isLinkValid(link)) {
                window.open(link, '_blank', 'noopener');
                this.dialog = false;
            }
        }
    }
};
</script>

<style>
.concert-item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 24px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.06);
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.concert-item:hover {
    background: rgba(255, 255, 255, 0.08);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.concert-passed {
    opacity: 0.5;
}

/* Tarih Bölümü */
.date-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.date-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    min-width: 70px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.date-day {
    font-size: 24px;
    font-weight: 700;
    color: whitesmoke;
    line-height: 1;
}

.date-month {
    font-size: 14px;
    color: #999;
    text-transform: uppercase;
}

.days-left {
    font-size: 12px;
    color: #999;
    text-align: center;
}

.days-today {
    color: #4CAF50;
    font-weight: 500;
}

.days-passed {
    color: #999;
}

/* Konser Detayları */
.concert-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}

.concert-name {
    color: whitesmoke;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.venue-info {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #999;
    font-size: 14px;
}

/* Bilet Butonu */
.ticket-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.buy-ticket-btn {
    color: whitesmoke !important;
    font-size: 0.9rem !important;
    letter-spacing: 0.5px !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    transition: all 0.2s ease !important;
    height: 36px !important;
}

.buy-ticket-btn:hover {
    background: rgba(255, 255, 255, 0.05) !important;
    border-color: rgba(255, 255, 255, 0.2) !important;
}

.no-ticket {
    color: #999;
    font-size: 0.9rem;
}

.ticket-dialog {
    background: #1E1E1E !important;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px !important;
    color: whitesmoke !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dialog-content {
    padding: 24px !important;
}

.ticket-options {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.ticket-option-btn {
    height: 44px !important;
    background: rgba(255, 255, 255, 0.05) !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    transition: all 0.2s ease !important;
    color: whitesmoke !important;
    font-size: 1rem !important;
    letter-spacing: 1px !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
}

.ticket-option-btn:disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
}

.coming-soon {
    font-size: 0.8rem;
    opacity: 0.7;
    font-weight: normal;
    letter-spacing: normal;
}

.ticket-option-btn:hover {
    background: rgba(255, 255, 255, 0.08) !important;
    border-color: rgba(255, 255, 255, 0.2) !important;
}

/* Responsive Tasarım */
@media (max-width: 960px) {
    .concert-item {
        gap: 20px;
        padding: 18px;
    }

    .concert-name {
        font-size: 17px;
    }

    .buy-ticket-btn {
        font-size: 0.85rem !important;
    }
}

@media (max-width: 768px) {
    .concert-item {
        gap: 18px;
        padding: 16px;
    }

    .date-box {
        min-width: 65px;
    }

    .concert-name {
        font-size: 16px;
    }

    .venue-info {
        font-size: 13.5px;
    }

    .buy-ticket-btn {
        height: 34px !important;
    }
}

@media (max-width: 600px) {
    .concert-item {
        grid-template-columns: auto 1fr;
        gap: 16px;
        padding: 16px;
    }

    .ticket-section {
        grid-column: 1 / -1;
        justify-content: stretch;
        padding-top: 12px;
        border-top: 1px solid rgba(255, 255, 255, 0.05);
    }

    .buy-ticket-btn, .no-ticket {
        width: 100%;
        justify-content: center;
    }

    .concert-name {
        font-size: 15px;
    }

    .venue-info {
        font-size: 13px;
    }

    .date-box {
        padding: 6px 12px;
        min-width: 60px;
    }

    .date-day {
        font-size: 20px;
    }

    .date-month {
        font-size: 12px;
    }

    .days-left {
        font-size: 11px;
    }
}

@media (max-width: 480px) {
    .concert-item {
        gap: 14px;
        padding: 14px;
    }

    .date-box {
        min-width: 55px;
        padding: 5px 10px;
    }

    .date-day {
        font-size: 18px;
    }

    .date-month {
        font-size: 11px;
    }

    .concert-name {
        font-size: 14px;
    }

    .venue-info {
        font-size: 12px;
    }

    .buy-ticket-btn {
        font-size: 0.8rem !important;
        height: 32px !important;
    }

    .no-ticket {
        font-size: 0.8rem;
    }

    .ticket-dialog {
        margin: 12px;
    }
}

@media (max-width: 360px) {
    .concert-item {
        gap: 12px;
        padding: 12px;
    }

    .date-box {
        min-width: 50px;
        padding: 4px 8px;
    }

    .date-day {
        font-size: 16px;
    }

    .date-month {
        font-size: 10px;
    }

    .concert-name {
        font-size: 13px;
    }

    .venue-info {
        font-size: 11px;
    }

    .days-left {
        font-size: 10px;
    }

    .buy-ticket-btn {
        font-size: 0.75rem !important;
        height: 30px !important;
    }

    .no-ticket {
        font-size: 0.75rem;
    }
}
</style>