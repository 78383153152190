<template>
  <v-app style="background-color: #191919;">
    <v-main>
      <v-container class="fill-height">
        <v-row justify="center" align="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-card-title class="text-center py-4">
                Admin Girişi
              </v-card-title>
              <v-card-text>
                <v-form @submit.prevent="login" ref="form">
                  <v-text-field
                    v-model="username"
                    label="Kullanıcı Adı"
                    prepend-icon="mdi-account"
                    type="text"
                    required
                    :rules="[v => !!v || 'Kullanıcı adı gerekli']"
                  ></v-text-field>

                  <v-text-field
                    v-model="password"
                    label="Parola"
                    prepend-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    required
                    :rules="[v => !!v || 'Parola gerekli']"
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>

                  <v-btn
                    color="primary"
                    block
                    class="mt-4"
                    type="submit"
                    :loading="loading"
                  >
                    Giriş Yap
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'LoginView',
  data: () => ({
    username: '',
    password: '',
    showPassword: false,
    loading: false
  }),
  methods: {
    login() {
      if (!this.$refs.form.validate()) return

      this.loading = true
      
      const adminUsername = process.env.VUE_APP_ADMIN_USERNAME
      const adminPassword = process.env.VUE_APP_ADMIN_PASSWORD
      
      if (this.username === adminUsername && this.password === adminPassword) {
        localStorage.setItem('isAdminAuthenticated', 'true')
        this.$router.push('/admin')
      } else {
        alert('Hatalı kullanıcı adı veya parola!')
      }
      
      this.loading = false
    }
  }
}
</script> 