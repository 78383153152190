<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
.v-application {
  overflow-x: hidden;
  width: 100%;
}
</style>