<template>
  <v-app style="background-color: #191919;">
    <v-main>
      <v-container>
        <v-row justify="space-between" align="center" class="mb-6">
          <v-col cols="auto">
            <h1 class="text-h4 text-white">Admin Panel</h1>
          </v-col>
          <v-col cols="auto">
            <v-btn color="error" @click="logout">Çıkış Yap</v-btn>
          </v-col>
        </v-row>

        <!-- Toplu Konser Ekleme -->
        <v-card class="mb-6">
          <v-card-title>Toplu Konser Ekle</v-card-title>
          <v-card-text>
            <v-textarea
              v-model="bulkConcerts"
              label="Konser Listesi"
              hint="Her satıra bir konser gelecek şekilde: TARİH : KONSER ADI, MEKAN formatında girin"
              persistent-hint
              rows="10"
            ></v-textarea>
            <v-btn
              color="primary"
              block
              class="mt-4"
              @click="addBulkConcerts"
              :loading="bulkLoading"
            >
              Konserleri Ekle
            </v-btn>
          </v-card-text>
        </v-card>

        <!-- Tekli Konser Ekleme Formu -->
        <v-card class="mb-6">
          <v-card-title>Yeni Konser Ekle</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newConcert.name"
                    label="Konser Adı"
                    required
                    :rules="[v => !!v || 'Konser adı gerekli']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="newConcert.city"
                    :items="cities"
                    label="Şehir"
                    required
                    :rules="[v => !!v || 'Şehir seçimi gerekli']"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newConcert.venue"
                    label="Mekan"
                    required
                    :rules="[v => !!v || 'Mekan adı gerekli']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newConcert.date"
                    label="Tarih"
                    type="date"
                    required
                    :rules="[v => !!v || 'Tarih gerekli']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="newConcert.biletix"
                    label="Biletix Linki"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="newConcert.passo"
                    label="Passo Linki"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="newConcert.hammer"
                    label="Hammer Linki"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn
                color="primary"
                block
                @click="saveConcert"
                :loading="loading"
                :disabled="!valid"
              >
                Konseri Kaydet
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>

        <!-- Konser Listesi -->
        <v-card>
          <v-card-title>
            Konserler
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Ara"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="concerts"
            :search="search"
            :loading="loading"
          >
            <template #[`item.actions`]="{ item }">
              <v-btn
                icon
                small
                color="error"
                @click="deleteConcert(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { db } from '@/firebase'
import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore'

export default {
  name: 'AdminPanel',
  data: () => ({
    valid: false,
    loading: false,
    bulkLoading: false,
    search: '',
    bulkConcerts: '',
    cities: ['İstanbul', 'Ankara', 'İzmir', 'Bursa', 'Eskişehir'],
    concerts: [],
    headers: [
      { text: 'Konser Adı', value: 'name' },
      { text: 'Şehir', value: 'city' },
      { text: 'Mekan', value: 'venue' },
      { text: 'Tarih', value: 'date' },
      { text: 'İşlemler', value: 'actions', sortable: false }
    ],
    newConcert: {
      name: '',
      city: '',
      venue: '',
      date: '',
      biletix: '',
      passo: '',
      hammer: ''
    }
  }),
  methods: {
    async fetchConcerts() {
      this.loading = true
      try {
        const querySnapshot = await getDocs(collection(db, 'events'))
        this.concerts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      } catch (error) {
        console.error('Error fetching concerts:', error)
      } finally {
        this.loading = false
      }
    },
    async saveConcert() {
      if (!this.$refs.form.validate()) return

      this.loading = true
      try {
        await addDoc(collection(db, 'events'), this.newConcert)
        this.$refs.form.reset()
        this.fetchConcerts()
      } catch (error) {
        console.error('Error saving concert:', error)
      } finally {
        this.loading = false
      }
    },
    async deleteConcert(item) {
      if (!confirm('Bu konseri silmek istediğinize emin misiniz?')) return

      this.loading = true
      try {
        await deleteDoc(doc(db, 'events', item.id))
        this.fetchConcerts()
      } catch (error) {
        console.error('Error deleting concert:', error)
      } finally {
        this.loading = false
      }
    },
    async addBulkConcerts() {
      if (!this.bulkConcerts.trim()) return

      this.bulkLoading = true
      const lines = this.bulkConcerts.split('\n')
      const concerts = []

      for (const line of lines) {
        if (!line.trim()) continue

        try {
          // "DD Ay : Konser Adı, Mekan" formatını ayrıştır
          const [datePart, rest] = line.split(' : ')
          const [name, venue] = rest.split(', ')

          // Ay adını sayıya çevir
          const monthMap = {
            'Ocak': '01',
            'Şubat': '02',
            'Mart': '03',
            'Nisan': '04',
            'Mayıs': '05',
            'Haziran': '06',
            'Temmuz': '07',
            'Ağustos': '08',
            'Eylül': '09',
            'Ekim': '10',
            'Kasım': '11',
            'Aralık': '12'
          }

          // Tarih formatını düzenle
          const [day, month] = datePart.split(' ')
          const year = '2025'
          const formattedDay = day.padStart(2, '0')
          const formattedMonth = monthMap[month]
          const formattedDate = `${year}-${formattedMonth}-${formattedDay}`

          concerts.push({
            name: name.trim(),
            city: 'İstanbul',
            venue: venue.trim(),
            date: formattedDate,
            biletix: '',
            passo: '',
            hammer: ''
          })
        } catch (error) {
          console.error('Error parsing line:', line, error)
        }
      }

      // Veritabanına ekle
      try {
        for (const concert of concerts) {
          await addDoc(collection(db, 'events'), concert)
        }
        this.bulkConcerts = ''
        this.fetchConcerts()
        alert(`${concerts.length} konser başarıyla eklendi.`)
      } catch (error) {
        console.error('Error adding bulk concerts:', error)
        alert('Konserler eklenirken bir hata oluştu.')
      } finally {
        this.bulkLoading = false
      }
    },
    logout() {
      localStorage.removeItem('isAdminAuthenticated')
      this.$router.push('/')
    }
  },
  mounted() {
    this.fetchConcerts()
  }
}
</script>

<style scoped>
.v-main {
  padding-top: 24px !important;
}
</style> 